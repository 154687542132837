import React from 'react'
import StudyLayout from "../../components/studio/studioLayout"
import Year from "../../components/studio/year"

const Bew = () => {
    const imageData = [
        {
            name: "p1",
            width: 485,
        },
        {
            name: "p2",
            width: 420,
        },
        {
            name: "p3",
            width: 564,
        },
        {
            name: "p4",
            width: 429,
        },
        {
            name: "p5",
            width: 420,
        },
        {
            name: "p6",
            width: 449,
        },
        {
            name: "p7",
            width: 420,
        },
        {
            name: "p8",
            width: 420,
        },
        {
            name: "p9",
            width: 526,
        },
        {
            name: "p10",
            width: 378,
        },
        {
            name: "p11",
            width: 465,
        },
        {
            name: "p12",
            width: 420,
        },
        {
            name: "p13",
            width: 538,
        },
        {
            name: "p14",
            width: 336,
        },
        {
            name: "p15",
            width: 420,
        },
        {
            name: "p16",
            width: 430,
        },
        {
            name: "p17",
            width: 420,
        },
        {
            name: "p18",
            width: 600,
        },
        {
            name: "p19",
            width: 420,
        },
        {
            name: "p20",
            width: 420,
        },
        {
            name: "p21",
            width: 449,
        },
        {
            name: "p22",
            width: 487,
        },
        {
            name: "p23",
            width: 420,
        },
        {
            name: "p24",
            width: 420,
        },
        {
            name: "p25",
            width: 335,
        },
        {
            name: "p26",
            width: 420,
        },
        {
            name: "p27",
            width: 420,
        },
        {
            name: "p28",
            width: 420,
        },
        {
            name: "p29",
            width: 583,
        },
        {
            name: "p30",
            width: 336,
        },
        {
            name: "p31",
            width: 434,
        },
        {
            name: "p32",
            width: 420,
        },
        {
            name: "p33",
            width: 454,
        },
        {
            name: "p34",
            width: 490,
        },
        {
            name: "p35",
            width: 438,
        },
        {
            name: "p36",
            width: 420,
        },

        {
            name: "p37",
            width: 353,
        },
        {
            name: "p38",
            width: 540,
        },
        {
            name: "p39",
            width: 346,
        },
        {
            name: "p40",
            width: 420,
        },
        {
            name: "p41",
            width: 534,
        },
        {
            name: "p42",
            width: 336,
        },
        {
            name: "p43",
            width: 463,
        },
        {
            name: "p44",
            width: 560,
        },
        {
            name: "p45",
            width: 336,
        },
        {
            name: "p46",
            width: 336,
        },
        {
            name: "p47",
            width: 404,
        },
        {
            name: "p48",
            width: 391,
        },
        {
            name: "p49",
            width: 476,
        },
        {
            name: "p50",
            width: 336,
        },
        {
            name: "p51",
            width: 336,
        },
        {
            name: "p52",
            width: 458,
        },
        {
            name: "p53",
            width: 446,
        },
        {
            name: "p54",
            width: 803,
        },
        {
            name: "p55",
            width: 598,
        },
        {
            name: "p56",
            width: 420,
        },
        {
            name: "p57",
            width: 560,
        },
        {
            name: "p58",
            width: 463,
        },
        {
            name: "p59",
            width: 522,
        },
        {
            name: "p60",
            width: 456,
        },
        {
            name: "p61",
            width: 481,
        },
        {
            name: "p62",
            width: 420,
        },
        {
            name: "p63",
            width: 612,
        },
        {
            name: "p64",
            width: 420,
        },
        {
            name: "p65",
            width: 336,
        },
        {
            name: "p66",
            width: 582,
        },
        {
            name: "p67",
            width: 803,
        },
        {
            name: "p68",
            width: 420,
        },
        {
            name: "p69",
            width: 478,
        },
        {
            name: "p70",
            width: 535,
        },
        {
            name: "p71",
            width: 336,
        },
        {
            name: "p72",
            width: 420,
        },
        {
            name: "p73",
            width: 493,
        },
        {
            name: "p74",
            width: 606,
        },
        {
            name: "p75",
            width: 522,
        },
        {
            name: "p76",
            width: 420,
        },

        {
            name: "p77",
            width: 630,
        },
        {
            name: "p78",
            width: 551,
        },
        {
            name: "p79",
            width: 590,
        },
        {
            name: "p80",
            width: 560,
        },
        {
            name: "p81",
            width: 420,
        },
        {
            name: "p82",
            width: 420,
        },
        {
            name: "p83",
            width: 469,
        },
        {
            name: "p84",
            width: 581,
        },
        {
            name: "p85",
            width: 336,
        },
        {
            name: "p86",
            width: 420,
        },
        {
            name: "p87",
            width: 478,
        },
        {
            name: "p88",
            width: 463,
        },
        {
            name: "p89",
            width: 631,
        },
        {
            name: "p90",
            width: 527,
        },
        {
            name: "p91",
            width: 631,
        },
        {
            name: "p92",
            width: 498,
        },
        {
            name: "p93",
            width: 536,
        },
        {
            name: "p94",
            width: 522,
        },
        {
            name: "p95",
            width: 420,
        },
        {
            name: "p96",
            width: 451,
        },
        {
            name: "p97",
            width: 434,
        },
        {
            name: "p98",
            width: 560,
        },
        {
            name: "p99",
            width: 483,
        },
        {
            name: "p100",
            width: 631,
        },
        {
            name: "p101",
            width: 560,
        },
        {
            name: "p102",
            width: 428,
        },
        {
            name: "p103",
            width: 420,
        },
        {
            name: "p104",
            width: 363,
        },
        {
            name: "p105",
            width: 384,
        },
        {
            name: "p106",
            width: 560,
        },
        {
            name: "p107",
            width: 630,
        },
        {
            name: "p108",
            width: 420,
        },
        {
            name: "p109",
            width: 336,
        },
        {
            name: "p110",
            width: 598,
        },
        {
            name: "p111",
            width: 598,
        },
        {
            name: "p112",
            width: 420,
        },
        {
            name: "p113",
            width: 501,
        },
        {
            name: "p114",
            width: 594,
        },
        {
            name: "p115",
            width: 560,
        },
        {
            name: "p116",
            width: 336,
        },

        {
            name: "p117",
            width: 630,
        },
        {
            name: "p118",
            width: 630,
        },
        {
            name: "p119",
            width: 630,
        },
        {
            name: "p120",
            width: 420,
        },
        {
            name: "p121",
            width: 591,
        },
        {
            name: "p122",
            width: 599,
        },
        {
            name: "p123",
            width: 353,
        },
        {
            name: "p124",
            width: 336,
        },
        {
            name: "p125",
            width: 420,
        },
        {
            name: "p126",
            width: 380,
        },
        {
            name: "p127",
            width: 315,
        },
        {
            name: "p128",
            width: 560,
        },
        {
            name: "p129",
            width: 537,
        },
        {
            name: "p130",
            width: 315,
        },
        {
            name: "p131",
            width: 420,
        },
        {
            name: "p132",
            width: 404,
        },
        {
            name: "p133",
            width: 513,
        },
        {
            name: "p134",
            width: 560,
        },
        {
            name: "p135",
            width: 485,
        },
        {
            name: "p136",
            width: 560,
        },
        {
            name: "p137",
            width: 560,
        },
        {
            name: "p138",
            width: 450,
        },
        {
            name: "p139",
            width: 560,
        },
        {
            name: "p140",
            width: 315,
        },
        {
            name: "p141",
            width: 315,
        },
        {
            name: "p142",
            width: 315,
        },
        {
            name: "p143",
            width: 315,
        },

    ]

    return (
        <StudyLayout year={2019}>
            <Year imageData={imageData} year={19} />
        </StudyLayout>
    );
}

export default Bew;